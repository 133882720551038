export default Object.freeze({
  'mia-beach-day-club-tulum': {
    caption: 'Restaurant & Beach Club',
    logo:
      'https://imgix.cosmicjs.com/988e3350-d4d6-11ee-9ce5-59949019255e-logo-mia-beach-club-tulum.png',
  },
  'bagatelle-beach-club-tulum': {
    caption: 'Living The French Joy de vivre',
    logo:
      'https://imgix.cosmicjs.com/3c946670-d5a6-11ee-9ce5-59949019255e-logo-bagatelle-tulum.png',
  },
  'vagalume-beach-club-tulum': {
    caption: 'Beach Club & Night Club',
    logo:
      'https://imgix.cosmicjs.com/0e7e1e60-d4cb-11ee-9ce5-59949019255e-logo-vagalume-tulum.png',
  },
  'rosa-negra-beach-club-tulum': {
    caption: 'Feel Alive',
    logo:
      'https://imgix.cosmicjs.com/c6e93110-d5a7-11ee-9ce5-59949019255e-logo-rosanegra-beach-club-tulum.png',
  },
  'gitano-beach-club-tulum': {
    logo:
      'https://imgix.cosmicjs.com/d72577a0-d4da-11ee-9ce5-59949019255e-logo-gitano-beach-tulum.png',
    caption: 'Path To Paradise',
  },
  'taboo-beach-day-club-tulum': {
    caption: 'Beach Club Party & Live Shows',
    logo:
      'https://imgix.cosmicjs.com/616c3fe0-d4d9-11ee-9ce5-59949019255e-logo-taboo-tulum.png',
  },
  'tantra-beach-club-tulum': {
    name: 'Tantra Tulum',
    caption: 'Bohemian restaurant & Beach Club',
    logo:
      'https://imgix.cosmicjs.com/53ed4f20-d5a7-11ee-9ce5-59949019255e-logo-tantra-tulum.png',
  },
  'balabamba-beach-club-tulum': {
    name: 'Balabamba Tulum',
    logo:
      'https://imgix.cosmicjs.com/bd0fae50-d4d8-11ee-9ce5-59949019255e-logo-balabamba-tulum.png',
  },
  "buuts-ha-cenote-day-club-tulum": {
    logo: 'https://imgix.cosmicjs.com/f491b020-d5a6-11ee-9ce5-59949019255e-logo-buuts-ha-tulum.png',
    name: 'Buuts Ha',
  },
  "kanan-beach-day-club-tulum": {
    name: 'Kanan Beach Club',
    caption: 'Restaurant & Bar',
    logo:
      'https://imgix.cosmicjs.com/fe5e9890-d4dc-11ee-9ce5-59949019255e-logo-kanan-beach-club-tulum.png',
  },
  "panamera-beach-club-tulum": {
    caption: 'Lifestyle boutique hotel & Beach Club',
    logo:
      'https://imgix.cosmicjs.com/d0f8b2b0-8bf6-11ef-b5a0-93db72e2be98-panamera-tulum-logo.jpg',
  },
  "ahau-beach-club-tulum": {
    logo:
      'https://imgix.cosmicjs.com/3d2d58a0-8ca1-11ef-b5a0-93db72e2be98-ahau-tulum-clubs.jpg',
    caption: 'Beachfront resort between the jungle and the sea'
  },
  "soy-tulum-beach-club-tulum": {
    logo:
      'https://imgix.cosmicjs.com/3d2d58a0-8ca1-11ef-b5a0-93db72e2be98-ahau-tulum-clubs.jpg',
    caption: 'Bar & Seafood Restaurant'
  },
  "la-zebra-beach-club-tulum": {
    logo: 'https://imgix.cosmicjs.com/84e509e0-8d69-11ef-b5a0-93db72e2be98-tulum-clubs-la-zebra-logo.jpg',
    caption: 'Authentic Mexican cuisine, delicious craft cocktails',
  }
});
